<template>
  <v-app>
    <div class="row">

      <!-- Main Content -->
      <div class="col-md-12">
        <Card :title="'Kalender Perjanjian'">
          <template v-slot:body>
            <h5>*Keterangan Warna</h5>
            <div class="d-flex mb-2">
              <div
                v-for="color in statusColor"
                :key="color.id"
              >
                <b-button
                  pill
                  size="sm"
                  :class="`${color.color_class} text-white mx-1`"
                  style="border: none"
                >{{ color.name }}</b-button>
              </div>
            </div>
            <div class="row justify-content-end align-items-center mt-n3 mb-1">
              <!-- Filter By Status -->
              <div class="col-md-3 col-6">
                <treeselect
                  id="input-status"
                  v-model="filter.appointment_status_id"
                  :multiple="false"
                  :options="appointmentStatuses"
                  placeholder="Filter Berdasar Status"
                  @select="filterStatusOnSelect"
                />
              </div>
              <!-- Filter By Doctor -->
              <div
                class="col-md-3 col-6"
                v-if="currentUser.user_type_id != 1"
              >
                <treeselect
                  id="input-doctor"
                  v-model="filter.doctor_id"
                  :multiple="false"
                  :options="doctors"
                  placeholder="Filter Berdasar Dokter"
                  @select="filterDoctorOnSelect"
                />
              </div>
            </div>
            <!-- Calendar -->
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                  bottom
                  right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Per Hari</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Per Minggu</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Per Bulan</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>Per 4 Hari</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                v-model="value"
                locale="id"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="getEvents"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  flat
                >
                  <v-toolbar
                    :color="selectedEvent.color"
                    dark
                  >
                    <v-toolbar-title v-html="`${selectedEvent.name}`"></v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text style="font-size: 13px">
                    <p class="py-0">{{ selectedEvent.details }}</p>
                    <p class="py-0">{{ selectedEvent.notes }}</p>
                    <p class="py-0">{{ selectedEvent.status }}</p>
                    <p class="py-0">{{ selectedEvent.action }}</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="secondary"
                      @click="selectedOpen = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </template>
        </Card>
      </div>

    </div>
  </v-app>
</template>

<script>

import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { getUser } from '@/core/services/jwt.service.js'
import AppointmentModule from '@/core/modules/AppointmentModule.js'

export default {

  components: {
    Card,
    Treeselect
  },

  data: () => ({
    // Filter
    filter: {
      doctor_id: '',
      appointment_status_id: ''
    },
    statusColor: [],
    // Filter Options
    doctors: [],
    appointmentStatuses: [],
    // Calendar
    type: 'month',
    typeToLabel: {
      month: 'Per Bulan',
      week: 'Per Minggu',
      day: 'Per Hari',
      '4day': 'Per 4 Hari',
    },
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    // Event
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    // current user
    currentUser: getUser()
  }),

  methods: {

    async getDoctors() {
      let response = await module.setDoctorTreeSelect('doctors')
      // Success
      if (response.state == 'success') {
        // Set Option Select
        this.doctors = response.data
        this.doctors.unshift({ label: 'Filter Berdasar Dokter', id: '', isDisabled: true })
      }
    },

    async getAppointmentStatuses() {
      let result = await AppointmentModule.getAppointmentStatuses()
      if (result) {
        this.appointmentStatuses = result.treeselect
        this.appointmentStatuses.unshift({ label: 'Filter Berdasar Status', id: '', isDisabled: true })
        this.statusColor = result.data
        console.log(result.data ,"COLOR ==00==00==00==00");
      }
    },

    async getAppointment() {
      return await module.list(`appointments/get-calendar?doctor_id=${this.filter.doctor_id}&appointment_status_id=${this.filter.appointment_status_id}`)
    },

    async filterDoctorOnSelect(evt) {
      await this.filter.doctor_id
      this.getEvents()
    },

    async filterStatusOnSelect(evt) {
      await this.filter.appointment_status_id
      this.getEvents()
    },

    async getEvents() {
      const events = []
      const appointments = await this.getAppointment(this.filter.doctor_id)
      const eventCount = appointments.length
      for (let i = 0; i < eventCount; i++) {
        console.info('color', appointments[i].appointment_status_col)
        events.push({
          name: appointments[i].name,
          start: new Date(appointments[i].start_time),
          end: new Date(appointments[i].end_time),
          doctor_name: appointments[i].doctor_name,
          notes: appointments[i].notes,
          status_name: appointments[i].appointment_status_name,
          action_type_name: appointments[i].action_type_name,
          color: appointments[i].appointment_status_color,
          timed: true,
        })
      }
      this.events = events
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        let dayName = new Date(event.start).toLocaleDateString('id-ID', { weekday: 'long' })
        let start = new Date(event.start).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' })
        let end = new Date(event.end).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' })
        this.selectedEvent.details = `${event.name} Pada ${dayName} ${start} s/d ${end} dengan ${event.doctor_name}`
        this.selectedEvent.notes = `Catatan: ${event.notes}`
        this.selectedEvent.status = `Status: ${event.status_name}`
        this.selectedEvent.action = `Tindakan: ${event.action_type_name}`
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 30)
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 30)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },

    viewDay({ date }) {
      this.value = date
      this.type = 'day'
    },

    setToday() {
      this.value = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    getEventColor(event) {
      return event.color
    },

    random(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },

  },

  watch: {
    'filter.doctor_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.doctor_id = ''
        this.filterDoctorOnSelect()
      }
    },
    'filter.appointment_status_id': function (newVal, oldVal) {
      if (typeof newVal === 'undefined') {
        this.filter.appointment_status_id = ''
        this.filterStatusOnSelect()
      }
    }
  },

  mounted() {
    this.$refs.calendar.checkChange()
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Perjanjian", route: "" },
      { title: "Kalender" },
    ])
    this.$refs.calendar.scrollToTime('08:00')
    if (this.currentUser.user_type_id != 1) {
      // Get Doctors Option
      this.getDoctors()
    } else {
      this.filter.doctor_id = this.currentUser.user_id
    }
    this.getAppointmentStatuses()
  },

}
</script>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";
.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>